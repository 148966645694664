<template>
  <div>
    <div v-for="(year, yearIndex) in years" :key="yearIndex">
      <h1>{{ year[0][0].plannedForYear }}</h1>

      <div v-for="(month, monthIndex) in year" :key="monthIndex">
        <h2>{{ $t('plannedTasks.tasksPlannedFor') }} {{ formatMonth(month[0].plannedForMonth) }}</h2>
        <v-data-iterator :items="month" :items-per-page="-1">
          <template v-slot:default="{ items }">
            <v-row v-for="task in   items" :key="task.taskId">
              <v-col>
                <TaskDisplay :expanded="showExpanded" :task="task" v-on:reload="loadData()" />
                <v-alert v-if="error" type="error">{{ error }}</v-alert>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </div>
    </div>

  </div>
</template>

<script>
import TaskDisplay from './TaskDisplay.vue'

export default {
  name: 'PlannedTasks',
  components: { TaskDisplay },

  data: () => ({
    error: null,
    showExpanded: false,
    years: []
  }),

  beforeMount () {
    this.loadData()
  },

  methods: {
    formatMonth (month) {
      const date = new Date()
      date.setMonth(month)

      return new Intl.DateTimeFormat(navigator.language, { month: 'long' }).format(date)
    },

    sortItemsPerYear () {
      this.years.sort(function (a, b) {
        return a[0][0].plannedForYear > b[0][0].plannedForYear ? 1 : -1
      })
    },

    sortItemsPerMonth (monthArray) {
      monthArray.sort(function (a, b) {
        return a.plannedForMonth > b.plannedForMonth ? 1 : -1
      })
    },

    loadData () {
      fetch('/api/tasks/filter?filter=planned', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const map = new Map()

          data.forEach(item => {
            const plannedForYear = item.plannedForYear
            const plannedForMonth = item.plannedForMonth

            if (!map.has(plannedForYear)) {
              map.set(plannedForYear, new Map())
            }

            if (!map.get(plannedForYear).has(plannedForMonth)) { map.get(plannedForYear).set(plannedForMonth, []) }

            map.get(plannedForYear).get(plannedForMonth).push(item)
          })

          map.forEach(monthMap => {
            const monthArray = []

            monthMap.forEach(array => {
              monthArray.push(array)
            })

            this.years.push(monthArray)
          })

          this.years.forEach(monthArray => this.sortItemsPerMonth(monthArray))
          this.sortItemsPerYear(this.years)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>
