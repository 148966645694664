<template>
  <dl class="task-overview">
    <dt>Global Tasks</dt>
    <dd>{{ $t('tasks.overview.report', {
      openTasks: taskOverview.global.openTasks, urgentTasks: taskOverview.global.urgentTasks,
      blockedTasks: taskOverview.global.blockedTasks
    }) }}</dd>

    <div v-for="taskSummary in taskOverview.zones" :key="taskSummary.zoneName">
      <dt><router-link :to="{ name: 'TasksPerZone', params: { zoneName: taskSummary.groupName } }">{{
        taskSummary.groupName
      }}</router-link></dt>
      <dd>{{ $t('tasks.overview.report', {
        openTasks: taskSummary.openTasks, urgentTasks: taskSummary.urgentTasks,
        blockedTasks: taskSummary.blockedTasks
      }) }}</dd>
    </div>
  </dl>
</template>

<style>
@media only screen and (max-width: 400px) {
  DIV.overview {
    flex-direction: column;
  }
}

DL.task-overview DT {
  font-weight: bold;
  margin-top: 1em;
}
</style>

<script>
export default {
  name: 'TasksPerZone',

  data: () => ({
    taskOverview: {
      global: {
        openTasks: 0,
        urgentTasks: 0,
        blockedTasks: 0
      }
    }
  }),

  beforeMount () {
    this.loadData()
  },

  methods: {
    loadData () {
      fetch('/api/tasks/overview', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.taskOverview = data
        })
    }

  }

}
</script>
