<template>
  <v-dialog v-model="showDialog" width="80vw">
    <v-form v-model="valid" @submit.prevent="comment()" ref="form">
      <v-card>
        <v-card-title>{{ $t('commentDialog.title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>{{ task.taskSubject }} </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="commentText" :label="$t('commentDialog.comment')" auto-grow rows="3" required
                :rules="requiredRules" ref="commentText" />
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col>
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{ $t('terms.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n'

export default {
  props: {
    show: Boolean,
    task: Object
  },

  data: () => ({
    valid: false,
    commentText: '',
    error: null,
    requiredRules: [
      v => !!v || i18n.t('commentDialog.requiredField')
    ]
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  watch: {
    showDialog: function (value) {
      if (value) {
        setTimeout(() => {
          this.$refs.form.reset()
          this.$refs.commentText.focus()
        })
      }
    }
  },

  methods: {
    comment () {
      this.$refs.form.validate()

      if (this.valid) {
        fetch(`/api/tasks/${this.task.taskId}/comment`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + `${this.$keycloak.token}`,
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            commentText: this.commentText
          })
        })
          .then((response) => {
            if (response.status === 200) {
              this.showDialog = false
              this.error = null
              this.$emit('reload')
            } else {
              this.error = response.statusText
            }
          })
          .catch((error) => {
            this.error = error
          })
      }
    }
  }
}
</script>
