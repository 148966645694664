export default {
  methods: {
    saveTask (task) {
      return fetch('/api/tasks', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(task)
      })
    },

    cancelTask () {
      return Promise.resolve()
    }

  }
}
