<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header :class="[(task.blocked ? 'blocked' : ''), (task.urgent ? 'urgent' : '')]">
              {{ task.taskNumber }}: {{ task.referencedZone }} - {{ task.taskSubject }} <span v-if="task.assignedToUser">
                &nbsp;({{ task.assignedToUser }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  {{ task.taskDescription }}
                </v-col>
              </v-row>
              <v-row v-if="task.images">
                <v-col>
                  <a target="_blank" :href="awsImagePath(task.images[0])"><img :src="awsImagePath(task.images[0])" /></a>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="task.imageReferences">
                  <div v-for="(imageReference, index) in task.imageReferences" :key="index">
                    <router-link :to="{ name: 'BigImage', params: { imagePath: imageReference.fullSizeURL } }">
                      <img :src="imageReference.thumbnailURL" :class="{ rotate90: rotateImage }" />
                    </router-link>
                    <v-icon v-if="!rotateImage" @click.stop="rotateImage = true">mdi-rotate-left</v-icon>
                    <v-icon v-if="rotateImage" @click.stop="rotateImage = false">mdi-rotate-right</v-icon>
                    <v-icon @click.stop="deletePicture(imageReference)">mdi-delete</v-icon>
                  </div>
                </v-col>
                <v-col>
                  <v-file-input label="File input" filled prepend-icon="mdi-camera" v-model="taskImage"
                    @change="addPicture()"></v-file-input>
                  {{ $t('terms.upload') }} {{ uploadPercentCompleted }} %
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  {{ $t('tasks.toolsNeeded') }}:
                  <span v-for="(tool, index) in task.toolsNeeded" :key="index">
                    {{ tool }}<span v-if="index < task.toolsNeeded.length - 1">,</span>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  {{ $t('tasks.externalURL') }} <v-icon color="primary"
                    @click="openExternalURL(task.externalURL)">mdi-open-in-new</v-icon>
                </v-col>
                <v-col>
                  {{ formatMoney(task.budget) }}
                </v-col>
              </v-row>
              <v-row v-if="task.comments != null">
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header :class="{ blocked: task.blocked }">{{ $t('tasks.latestComment') }}: {{
                        formatDateTime(task.comments[0].commentDateTime)
                      }}</v-expansion-panel-header>
                      <v-expansion-panel-content :class="{ blocked: task.blocked }">
                        <p v-for="(comment, index) in task.comments" :key="index">
                          {{ formatDateTime(comment.commentDateTime) }}: {{ comment.commentText }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>{{ $t('tasks.created') }}: {{ formatDateTime(task.taskDateTime) }} {{ $t('tasks.assignedTo') }}: {{
                    task.assignedToUser
                  }}</p>
                </v-col>
              </v-row>
              <v-row v-if="task.planned">
                <v-col>
                  <p class="planned-for">{{ $t('tasks.planned') }}: {{ task.plannedForMonth }} / {{
                    task.plannedForYear
                  }}</p>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row>
                  <v-col cols="9">
                    <v-btn v-if="task.open" :disabled="taskDisabled(task)" @click.stop="markDone()" color="primary"> {{
                      $t('tasks.actions.done')
                    }} </v-btn>

                    <v-icon large v-if="task.assignedToUser" :disabled="taskDisabled(task)" @click.stop="assignToMe()"
                      color="secondary">mdi-account</v-icon>
                    <v-icon v-else large :disabled="taskDisabled(task)" @click.stop="assignToMe()"
                      color="primary">mdi-account</v-icon>
                    <v-icon large color="primary" @click="shareTask(task)">mdi-export-variant</v-icon>
                  </v-col>
                  <v-col>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon large color="black" v-bind="attrs" v-on="on">mdi-menu</v-icon>
                      </template>
                      <v-list subheader>
                        <v-list-item>
                          <v-btn v-if="!task.blocked" :disabled="taskDisabled(task)" @click.stop="blockTask()"
                            color="secondary" text>
                            {{
                              $t('tasks.actions.block')
                            }}
                          </v-btn>
                          <v-btn v-if="task.blocked" :disabled="taskDisabled(task)" @click.stop="unblockTask()"
                            color="primary" text>
                            {{
                              $t('tasks.actions.unblock')
                            }}
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn :disabled="taskDisabled(task)" @click.stop="comment()" color="primary" text> {{
                            $t('tasks.actions.comment')
                          }} </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn v-if="!task.urgent" :disabled="taskDisabled(task)" @click.stop="toggleUrgent()"
                            color="red" text>
                            {{
                              $t('tasks.actions.urgent')
                            }} </v-btn>
                          <v-btn v-if="task.urgent" :disabled="taskDisabled(task)" @click.stop="toggleUrgent()"
                            color="black" text>
                            {{
                              $t('tasks.actions.resetUrgent')
                            }} </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn text :disabled="taskDisabled(task)" @click.stop="planTask()">{{
                            $t('tasks.actions.plan')
                          }} </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn text @click.stop="editTask()">{{ $t('tasks.actions.edit') }} </v-btn>
                        </v-list-item>
                        <v-subheader inset>{{ $t('terms.danger') }}</v-subheader>
                        <v-list-item v-if="!task.open">
                          <v-btn text color="green" @click.stop="reopenTask()">{{
                            $t('terms.reopen')
                          }}</v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn @click.stop="deleteTask()" color="red" text>{{
                            $t('terms.delete')
                          }}</v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-actions> </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

    </v-card>
    <CommentDialog :show="showCommentDialog" @input="showCommentDialog = $event" :task="task" v-on:reload="loadData()" />
    <BlockTaskDialog :show="showBlockTaskDialog" @input="showBlockTaskDialog = $event" :task="task" :unblock="doUnblock"
      v-on:reload="loadData()" />
    <ConfirmMarkDoneDialog :show="showConfirmMarkDoneDialog" @input="showConfirmMarkDoneDialog = $event" :task="task"
      v-on:reload="loadData()" />
    <ConfirmDeleteDialog :show="showConfirmDeleteDialog" @input="showConfirmDeleteDialog = $event" :task="task"
      v-on:reload="loadData()" />
    <ConfirmReopenDialog :show="showConfirmReopenDialog" @input="showConfirmReopenDialog = $event" :task="task"
      v-on:reload="loadData()" />
    <ConfirmReopenDialog :show="showConfirmReopenDialog" @input="showConfirmReopenDialog = $event" :task="task"
      v-on:reload="loadData()" />
    <ConfirmImageDeleteDialog :show="showConfirmImageDeleteDialog" :task="task"
      :imageReference="imageReferenceToBeDeleted" @input="showConfirmImageDeleteDialog = $event"
      v-on:reload="loadData()" />
    <PlanTaskDialog :show="showPlanTaskDialog" @input="showPlanTaskDialog = $event" :task="task"
      v-on:reload="loadData()" />
    <TaskDialog v-model="showEditTaskDialog" :task="task" :update="updateTask" :cancel="cancelTask"
      :after-update="loadData" />
  </div>
</template>

<script>
import formatting from '@/mixins/formatting'
import CommentDialog from './CommentDialog.vue'
import BlockTaskDialog from './BlockTask.vue'
import ConfirmMarkDoneDialog from './ConfirmMarkDoneDialog.vue'
import ConfirmDeleteDialog from './ConfirmDeleteDialog.vue'
import ConfirmReopenDialog from './ConfirmReopenDialog.vue'
import ConfirmImageDeleteDialog from './ConfirmImageDeleteDialog.vue'
import PlanTaskDialog from './PlanTaskDialog.vue'
import TaskDialog from './TaskDialog.vue'

export default {
  name: 'TaskDisplay',
  props: {
    task: Object
  },
  mixins: [formatting],

  components: {
    CommentDialog,
    BlockTaskDialog,
    ConfirmMarkDoneDialog,
    ConfirmDeleteDialog,
    ConfirmReopenDialog,
    ConfirmImageDeleteDialog,
    PlanTaskDialog,
    TaskDialog
  },

  data: () => ({
    doUnblock: false,
    showCommentDialog: false,
    showBlockTaskDialog: false,
    showConfirmMarkDoneDialog: false,
    showConfirmDeleteDialog: false,
    showConfirmReopenDialog: false,
    showConfirmImageDeleteDialog: false,
    showPlanTaskDialog: false,
    showEditTaskDialog: false,
    rotateImage: false,
    uploadPercentCompleted: 0,
    taskImage: {},
    imageReferenceToBeDeleted: {}
  }),

  methods: {
    openExternalURL (url) {
      window.open(url, '_blank')
    },

    taskDisabled (task) {
      return !task.open
    },

    awsImagePath (imageName) {
      return 'https://granja-eu-tasks.s3.eu-central-1.amazonaws.com/' + imageName
    },
    loadData () {
      this.$emit('reload')
    },
    comment () {
      this.showCommentDialog = true
    },
    blockTask () {
      this.showBlockTaskDialog = true
    },

    unblockTask () {
      this.doUnblock = true
      this.showBlockTaskDialog = true
    },

    markDone () {
      this.showConfirmMarkDoneDialog = true
    },

    toggleUrgent () {
      let url
      if (this.task.urgent) { url = `/api/tasks/${this.task.taskId}/urgent/reset` } else { url = `/api/tasks/${this.task.taskId}/urgent` }
      fetch(url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            this.error = null
            this.$emit('reload')
          } else {
            this.error = response.statusText
          }
        })
        .catch((error) => {
          this.error = error
        })
    },

    assignToMe () {
      fetch(`/api/tasks/${this.task.taskId}/assign-to-me`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status === 204) {
            this.error = null
            this.loadData()
          } else {
            this.error = response.statusText
          }
        })
        .catch((error) => {
          this.error = error
        })
    },

    deleteTask () {
      this.showConfirmDeleteDialog = true
    },

    reopenTask () {
      this.showConfirmReopenDialog = true
    },

    planTask () {
      this.showPlanTaskDialog = true
    },

    editTask () {
      this.showEditTaskDialog = true
    },

    addPicture () {
      const taskLocation = `/api/tasks/${this.task.taskId}`
      this.upload(taskLocation)
    },

    deletePicture (imageReference) {
      this.imageReferenceToBeDeleted = imageReference
      this.showConfirmImageDeleteDialog = true
    },

    upload (taskLocation) {
      this.error = ''
      this.message = ''

      const formData = new FormData()
      formData.append('file', this.taskImage)

      const request = new XMLHttpRequest()
      request.open('POST', `${taskLocation}/upload-picture`)
      request.setRequestHeader('Authorization', 'Bearer ' + `${this.$keycloak.token}`)

      request.upload.addEventListener('progress', (e) => {
        const percentCompleted = (e.loaded / e.total) * 100
        this.uploadPercentCompleted = Math.ceil(percentCompleted)
      })

      request.addEventListener('load', (e) => {
        const status = e.currentTarget.status
        const statusText = e.currentTarget.statusText

        if (status === 200) {
          this.$emit('reload')
        } else {
          this.error = statusText // TODO: show it
        }
      })

      request.send(formData)
      return request
    },

    shareTask (task) {
      const taskUrl = `${window.location.origin}/tasks/details/${task.taskId}`
      navigator.clipboard.writeText(taskUrl)
        .then(() => {
          alert('Task URL copied to clipboard')
        })
        .catch((error) => {
          console.error('Failed to copy task URL to clipboard:', error)
        })
    },

    cancelTask () {
      console.log('cancelTask')
      return Promise.resolve()
    },

    updateTask (task) {
      console.log('updateTask', task)
      return fetch(`/api/tasks/${task.taskId}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(task)
      })
    }
  }
}
</script>

<style scoped>
.blocked {
  background-color: rgb(249, 235, 235);
}

.urgent {
  border: 2px solid red;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.planned-for {
  color: red;
}
</style>
