<template>
  <v-dialog v-model="showDialog" width="80vw">
    <v-form @submit.prevent="mark()">
      <v-card>
        <v-card-title v-if="!unblock">{{ $t('reopenTaskDialog.title') }}</v-card-title>
        <v-card-title v-if="unblock">{{ $t('reopenTaskDialog.unblockTitle') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>{{ task.taskSubject }} </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="reason" :label="$t('reopenTaskDialog.reason')" auto-grow rows="3" />
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col>
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{ $t('terms.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    task: Object,
    unblock: Boolean
  },

  data: () => ({
    reason: '',
    error: null
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    mark () {
      fetch(`/api/tasks/${this.task.taskId}/reopen`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          reason: this.reason
        })
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.error = null
            this.$emit('reload')
          } else {
            this.error = response.statusText
          }
        })
        .catch((error) => {
          this.error = error
        })
    }
  }
}
</script>
