<template>
  <div>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <TaskDisplay :task="task" v-on:reload="loadTask(task.taskId)" />
  </div>
</template>

<script>
import TaskDisplay from './TaskDisplay.vue'

export default {
  name: 'TaskDetails',
  components: { TaskDisplay },

  data: () => ({
    task: {},
    error: ''
  }),

  beforeMount () {
    this.loadTask(this.$route.params.taskId)
  },
  methods: {
    loadTask (taskId) {
      fetch(`/api/tasks/${taskId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status !== 200) { this.error = response.statusText }

          return response
        })
        .then((response) => response.json())
        .then((data) => {
          this.task = data
        })
    }
  }
}
</script>
