<template>
  <v-dialog v-model="showDialog" width="80vw">
    <v-card>
      <v-card-title>{{ dialogTitle }}</v-card-title>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="saveTask()">
          <v-container>
            <v-row>
              <v-col cols="auto">
                <v-select v-model="localTask.referencedZone" :items="zoneNames" :label="$t('addTaskDialog.zoneName')" />
              </v-col>
              <v-col>
                <v-text-field v-model="localTask.taskSubject" :label="$t('addTaskDialog.taskSubject')" required
                  :rules="subjectRules" ref="taskSubjectField" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="localTask.urgent" :label="$t('addTaskDialog.taskUrgent')"></v-checkbox>
                <v-textarea v-model="localTask.toolsNeeded" :label="$t('addTaskDialog.toolsNeeded')" auto-grow rows="1" />
              </v-col>
              <v-col>
                <v-file-input label="File input" filled prepend-icon="mdi-camera" v-model="taskImage"></v-file-input>
                {{ $t('terms.upload') }} {{ uploadPercentCompleted }} %
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-if="localTask.externalURL" v-model="localTask.externalURL"
                  :label="$t('addTaskDialog.externalURL')" append-icon="mdi-open-in-new"
                  @click:append="openInNewWindow(localTask.externalURL)" />
                <v-text-field v-else v-model="localTask.externalURL" :label="$t('addTaskDialog.externalURL')" />
              </v-col>
              <v-col>
                <v-text-field v-model="localTask.budget" :label="$t('addTaskDialog.budget')" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea v-model="localTask.taskDescription" :label="$t('addTaskDialog.taskDescription')" auto-grow
                  rows="3" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click.stop="callCancel()"> {{ $t('terms.close') }} </v-btn>
        <v-btn color="primary" type="submit" @click="callSave()"> {{ $t('terms.save') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'TaskDialog',

  props: {
    value: {
      type: Boolean,
      required: true
    },

    task: {
      type: Object,
      required: false
    },

    save: {
      type: Function,
      required: false
    },

    update: {
      type: Function,
      required: false
    },

    cancel: {
      type: Function,
      required: true
    },

    afterUpdate: {
      type: Function,
      required: false
    }
  },

  watch: {
    task: {
      immediate: true,
      handler (newVal) {
        this.localTask = Object.assign({}, newVal)

        if (this.localTask.toolsNeeded && typeof this.localTask.toolsNeeded === 'string') {
          this.localTask.toolsNeeded = this.localTask.toolsNeeded.split(',').map(item => item.trim())
        }
      }
    }
  },

  data: () => ({
    localTask: null,
    valid: false,
    zoneNames: [],
    error: '',
    taskImage: null,
    uploadPercentCompleted: 0,
    subjectRules: [
      v => !!v || i18n.t('addTaskDialog.subjectRequired')
    ]
  }),

  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    dialogTitle () {
      return this.update ? i18n.t('editTaskDialog.title') : i18n.t('addTaskDialog.title')
    }
  },

  beforeMount () {
    this.loadZones()
  },

  methods: {
    openInNewWindow (url) {
      window.open(url, '_blank')
    },

    callSave () {
      if (this.valid) {
        const functionToCall = this.update ? this.update : this.save

        if (this.localTask.toolsNeeded && typeof this.localTask.toolsNeeded === 'string') {
          this.localTask.toolsNeeded = this.localTask.toolsNeeded.split(',').map(item => item.trim())
        }

        console.log('callSave', this.localTask)

        functionToCall(this.localTask)
          .then((response) => {
            const taskLocation = response.headers.get('location')

            if (this.taskImage) {
              this.upload(taskLocation)
            } else {
              this.showDialog = false
            }
          })
          .then(() => {
            if (this.afterUpdate) {
              this.afterUpdate()
            }
          })
          .catch((error) => {
            this.error = error
          })
      }
    },

    callCancel () {
      this.cancel()
        .then(() => {
          this.showDialog = false
        })
        .catch((error) => {
          this.error = error
        })
    },

    upload (taskLocation) {
      this.error = ''
      this.message = ''

      const formData = new FormData()
      formData.append('file', this.taskImage)

      const request = new XMLHttpRequest()
      request.open('POST', `${taskLocation}/upload-picture`)
      request.setRequestHeader('Authorization', 'Bearer ' + `${this.$keycloak.token}`)

      request.upload.addEventListener('progress', (e) => {
        const percentCompleted = (e.loaded / e.total) * 100
        this.uploadPercentCompleted = percentCompleted
      })

      request.addEventListener('load', (e) => {
        const status = e.currentTarget.status
        const statusText = e.currentTarget.statusText

        if (status === 200) {
          this.showDialog = false
        } else {
          this.error = statusText
        }
      })

      request.send(formData)
      return request
    },

    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zoneNames = data.zones.map(zone => {
            return zone.zoneName
          })
          this.zoneNames.sort()
        })
    }

  }
}
</script>
