<template>
  <div>
    <v-data-iterator v-if="items" :items="items" :items-per-page="-1">
      <template v-slot:header>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="addTask()">{{ $t('tasks.add') }}</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:default="{ items }">
        <v-row v-for="task in items" :key="task.taskId">
          <v-col>
            <TaskDisplay :expanded="showExpanded" :task="task" v-on:reload="loadUrgentTasks()" />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <TaskDialog v-model="showAddTaskDialog" :save="saveTask" :cancel="cancelTask" />
  </div>
</template>

<script>
import TaskDisplay from './TaskDisplay.vue'
import TaskDialog from './TaskDialog.vue'
import tasks from '@/mixins/tasks.js'

export default {
  name: 'TasksOverview',
  components: { TaskDisplay, TaskDialog },
  mixins: [tasks],

  data: () => ({
    items: null,
    showExpanded: false,
    showAddTaskDialog: false
  }),

  beforeMount () {
    this.loadUrgentTasks()
  },

  methods: {
    addTask () {
      this.showAddTaskDialog = true
    },

    loadUrgentTasks () {
      fetch('/api/tasks/filter?filter=urgent', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.items = data
        })
    }

  }
}
</script>
