<template>
  <v-dialog v-model="showDialog">
    <v-form @submit.prevent="markDone()">
      <v-card>
        <v-card-title>{{ $t('confirmMarkDoneDialog.title') }}</v-card-title>
        <v-card-text>
          {{ task.taskSubject }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{
            $t('confirmMarkDoneDialog.markAsDone')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    task: Object
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    markDone () {
      fetch(`/api/tasks/${this.task.taskId}/done`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(() => {
          this.showDialog = false
          this.$emit('reload')
        })
    }
  }
}
</script>
