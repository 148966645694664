<template>
  <div>
    <h1>{{ $t('tasks.tasksPerZone.title', { zoneName: $route.params.zoneName }) }}</h1>
    <v-data-iterator :items="items" :items-per-page="-1">
      <template v-slot:header>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="addTask()">{{ $t('tasks.add') }}</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:default="{ items }">
        <v-row v-for="task in items" :key="task.taskId">
          <v-col>
            <TaskDisplay :expanded="showExpanded" :task="task" v-on:reload="loadData()" />
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <TaskDialog v-model="showAddTaskDialog" :save="saveTask" :cancel="cancelTask" />
  </div>
</template>

<script>
import TaskDisplay from './TaskDisplay.vue'
import TaskDialog from './TaskDialog.vue'
import tasks from '@/mixins/tasks.js'

export default {
  name: 'TasksPerZone',

  components: { TaskDisplay, TaskDialog },
  mixins: [tasks],

  data: () => ({
    items: [],
    showExpanded: false,
    showAddTaskDialog: false,
    error: null
  }),

  watch: {
    showAddTaskDialog () {
      if (this.showAddTaskDialog === false) { this.loadData() }
    }
  },

  beforeMount () {
    this.loadData()
  },

  methods: {
    addTask () {
      this.showAddTaskDialog = true
    },

    loadData () {
      fetch(`/api/tasks/filter?filter=${this.$route.params.zoneName}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.items = data
        })
    }
  }
}
</script>
```
