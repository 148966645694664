<template>
  <div>
    <v-data-iterator :items="items" :search="search" :items-per-page="10">
      <template v-slot:header>
        <v-toolbar flat>
          <v-btn small color="primary" @click.stop="addTask()">{{ $t('tasks.add') }}</v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify"
            :label="$t('tasks.search')"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="filtersApplied" multiple @change="filter()" background-color="secondary">
            <v-btn small v-for="(value, index) in filtersAvailable" :key="index" :value="value">{{
              $t('tasks.filter.' + value) }}</v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="showOpenTasks" @change="toggleShowDoneTasks()" background-color="secondary">
            <v-btn small :value="true">{{ $t('tasks.showOpen') }}</v-btn>
            <v-btn small :value="false">{{ $t('tasks.showDone') }}</v-btn>
          </v-btn-toggle>
        </v-toolbar>
      </template>
      <template v-slot:default="{ items }">
        <v-row v-for="task in items" :key="task.taskId">
          <v-col>
            <TaskDisplay :expanded="showExpanded" :task="task" v-on:reload="loadData()" />
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <TaskDialog v-model="showAddTaskDialog" :save="saveTask" :cancel="cancelTask" />
  </div>
</template>

<script>
import TaskDialog from './TaskDialog.vue'
import TaskDisplay from './TaskDisplay.vue'
import tasks from '@/mixins/tasks.js'

export default {
  name: 'TasksRegular',
  components: { TaskDialog, TaskDisplay },
  mixins: [tasks],

  data: () => ({
    taskUrl: null,
    items: [],
    search: '',
    showExpanded: false,
    showAddTaskDialog: false,
    showOpenTasks: true,
    filtersApplied: [],
    filtersAvailable: ['urgent', 'blocked'],
    error: null
  }),

  watch: {
    showAddTaskDialog () {
      if (this.showAddTaskDialog === false) { this.loadData() }
    },

    error () {
      if (this.error) {
        setTimeout(() => {
          this.error = null
        }, 1500)
      }
    }
  },

  beforeMount () {
    this.taskUrl = '/api/tasks'
    this.loadData()
  },

  methods: {
    loadData () {
      const url = this.taskUrl ? this.taskUrl : '/api/tasks'

      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.items = data
        })
    },

    filter () {
      if (this.filtersApplied.includes('urgent') && this.filtersApplied.includes('blocked')) { this.taskUrl = '/api/tasks/filter?filter=urgent,blocked' } else if (this.filtersApplied.includes('urgent')) { this.taskUrl = '/api/tasks/filter?filter=urgent' } else if (this.filtersApplied.includes('blocked')) { this.taskUrl = '/api/tasks/filter?filter=blocked' } else { this.taskUrl = null }

      this.loadData()
    },

    addTask () {
      this.showAddTaskDialog = true
    },

    toggleShowDoneTasks () {
      if (this.showOpenTasks) {
        this.taskUrl = null
        this.showOpenTasks = true
      } else {
        this.taskUrl = '/api/tasks/done'
        this.showOpenTasks = false
      }
      this.loadData()
    }

  }
}
</script>
