<template>
  <v-dialog v-model="showDialog">
    <v-form @submit.prevent="plan()">
      <v-card>
        <v-card-title>{{ $t('planTaskDialog.title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>{{ task.taskSubject }} </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="months" v-model="taskPlan.plannedForMonth"
                :label="$t('planTaskDialog.plannedForMonth')" />
            </v-col>
            <v-col>
              <v-select :items="years" v-model="taskPlan.plannedForYear" :label="$t('planTaskDialog.plannedForYear')" />
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col>
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{ $t('terms.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n'

export default {
  props: {
    show: Boolean,
    task: Object
  },

  data: () => ({
    reason: '',
    taskPlan: {},
    error: null,
    months: [
      { text: i18n.t('terms.months.january'), value: 1 },
      { text: i18n.t('terms.months.february'), value: 2 },
      { text: i18n.t('terms.months.march'), value: 3 },
      { text: i18n.t('terms.months.april'), value: 4 },
      { text: i18n.t('terms.months.may'), value: 5 },
      { text: i18n.t('terms.months.june'), value: 6 },
      { text: i18n.t('terms.months.july'), value: 7 },
      { text: i18n.t('terms.months.august'), value: 8 },
      { text: i18n.t('terms.months.september'), value: 9 },
      { text: i18n.t('terms.months.october'), value: 10 },
      { text: i18n.t('terms.months.november'), value: 11 },
      { text: i18n.t('terms.months.december'), value: 12 }
    ]
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    },
    years () {
      const today = new Date()
      const thisYear = today.getFullYear()
      const nextYear = thisYear + 1
      const otherYear = nextYear + 1

      return [
        { text: thisYear, value: thisYear },
        { text: nextYear, value: nextYear },
        { text: otherYear, value: otherYear }
      ]
    }
  },

  beforeMount () {
    this.taskPlan = {
      plannedForMonth: this.task.plannedForMonth,
      plannedForYear: this.task.plannedForYear,
      taskId: this.task.taskId
    }
  },

  methods: {
    plan () {
      fetch('/api/tasks/plan', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(this.taskPlan)
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDialog = false
            this.error = null
            this.$emit('reload')
          } else {
            this.error = response.statusText
          }
        })
    }
  }
}
</script>
